import {
    $, addAction, addFilter, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => $(document).on('click', '.message__close', () => doAction('css-vars/refresh')));

addFilter('css-vars/register', (styles) => ({
    ...styles,
    'header-top': () => `${$('.message--show').outerHeight() || 0}px`,
}));
