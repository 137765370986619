import { addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    navText: [
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
    ],
    responsive: { 0: { items: 2 }, 768: { margin: 20, items: 3 } },
}));
