import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings) => ({
    ...settings,
    navText: [
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
    ],
    responsive: { 0: { items: 1 }, 768: { items: 1 } },
}));
